import React from "react";
import { PageProps, graphql } from "gatsby";
import styled from "styled-components";

import { Query } from "@graphql-types";

import SEO from "@components/shared/seo";
import { BlocksContent } from "@components/shared/sub";
import { TABLET_BREAKPOINT, fontSizes } from "@util/constants";
import { Container } from "@util/standard";
import { Blocks } from "@components/shared/blocks";

interface Props extends PageProps {
  data: Query;
  pagecontext: any;
}

export default function WorkTemplate({ data, pageContext }: Props) {
  if (data == null || data.sanityPage == null) return null;
  const page = data.sanityPage;
  if (page == null) {
    return null;
  }

  const { seo, hero, pageBlocks, pageHeading } = page;

  return (
    <PageWrapper>
      <SEO seoData={seo} slug={pageContext?.slug} />
      {hero?._rawColumnContent && (
        <HeroWrapper>
          <h1>{pageHeading}</h1>
          <BlocksContent blocks={hero?._rawColumnContent} />
        </HeroWrapper>
      )}
      <StyledBlocks data={pageBlocks as any[]} />
    </PageWrapper>
  );
}

export const query = graphql`
  query pageTemplateQuery($slug: String) {
    sanityPage(slug: { current: { eq: $slug } }) {
      ...sanityPage
    }
  }
`;

const PageWrapper = styled.div`
  h1,
  h2,
  h3,
  p,
  span {
    margin: 60px auto;
    text-align: left;
  }
  strong {
    font-size: inherit;
    line-height: inherit;
  }

  h1 {
    font-size: 100px;
    font-style: normal;
    font-weight: 400;
    line-height: 100px; /* 100% */
    width: fit-content;
    margin-left: 0;
    margin-top: 0px;
  }
  h2 {
    margin-top: 30px;
    width: 100%;
    line-height: 60px;
    font-size: 50px;
  }
  p {
    font-size: 20px;
    line-height: 24px;
    width: 80%;
    margin: 30px 0;
  }

  li {
    font-size: 20px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    .blocks-wrapper {
      max-width: 90%;
    }

    p {
      font-size: 20px;
      line-height: 24px;
      width: 100%;
      margin: 30px auto;
    }

    h2,
    h3 {
      margin: 10px 0;
    }
  }
`;

const HeroWrapper = styled.div`
  width: 90%;
  margin: 250px auto 100px 5%;
  max-width: 66%;

  h1,
  h2,
  h3,
  p,
  span {
    margin: 60px auto;
    text-align: left;
  }
  strong {
    font-size: 15px;
    line-height: 18px;
  }

  h1 {
    font-size: 100px;
    font-style: normal;
    font-weight: 400;
    line-height: 100px; /* 100% */
    width: fit-content;
    margin-left: 0;
  }
  p {
    font-size: 20px;
    line-height: 24px;
    width: 80%;
    margin: 30px auto;
  }

  .blocks-content {
    max-width: 66%;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    margin: 125px auto 100px 5%;

    h1 {
      font-size: 50px;
      line-height: ${fontSizes.h1.mobile * 1.5}px !important;
      margin: 0;
    }
    .heading-2 {
      font-size: 30px;
      line-height: 1.3;
      margin-bottom: 0px;
    }
    .heading-3 {
      margin: 20px 0;
      font-size: 20px;
      line-height: 30px;
    }
    p {
      font-size: 15px;
      line-height: 18px;
      width: 100%;
      margin: 25px auto;
    }
    strong {
      font-size: 15px;
      line-height: 18px;
    }

    max-width: 90%;
  }
`;

const StyledBlocks = styled(Blocks)`
  .heading-2 {
    font-size: 50px;
  }
`;

const ColumnContainer = styled(Container)`
  margin: 0 auto 50px auto;
  text-align: center;
  h2,
  h3,
  h4,
  span,
  p,
  strong {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
`;
